<template>
    <div class="view" ref="container" @touchstart="swipeStart($event)" @touchend="swipeEnd($event)">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'15px'"/>
            </div>
            <div class="head_title">People</div>
        </div>
        <div class="main">
            <div class="loading" v-if="loading">
                <div class="spin"></div>
            </div>
            <div v-else>
                <div class="title">God Father</div>
                <card v-for="god_father in god_fathers" :key="god_father.username" :person="god_father"/>
                
                <div class="title">New Members</div>
                <card v-for="person in people" :key="person.username" :person="person"/>
            </div>
        </div>
        
        <menulayout />
    </div>
</template>

<script>
import back from '../../icons/back.vue'
import card from '../../components/people/card.vue'

import menulayout from '../../components/menu.vue';

import pull_to_refresh from '../../mixins/pull_to_refresh.js'

export default {
    components: {
        back,
        card,
        menulayout
    },
    data() {
        return {
            loading: false,
            god_fathers: [],
            people: [],
            
        }
    },
    mounted() {
        this.fetch_people()
    },
    mixins: [pull_to_refresh],
    methods: {
        back() {
            this.$router.go(-1)
        },
        refresh() {
            this.fetch_people()
        },
        fetch_people() {
            this.loading = true
            this.$http.post('/people/get')
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.god_fathers = r.god_fathers
                    this.people = r.users
                    this.loading = false
                }
            })
        },

    }
}
</script>

<style scoped>
    .view {
        background-color: white;
    }
    .head {
        position: relative;
        /* display: grid; */
        /* grid-template-columns: calc(50% - 27px) 50%; */
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
    }
    .back {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 50px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
    }
    .back:active {
        background-color: var(--trans);
    }
    .head_title {
        font-weight: 600;
        text-align: center;
    }
    
    .main {
        height: calc(100vh - 110px);
        overflow: auto;
        padding: 15px;
        box-sizing: border-box;
        background-color: #edeef0;
    }

    .loading {
        margin-top: 20px;
    }
    .spin {
        width: 20px;
        height: 20px;
        margin: 0 auto;
    }


    .title {
        font-weight: 500;
        margin-bottom: 20px;
    }
</style>